<template>
  <div class="from_outer">
    <div>
      <el-form ref="formName" label-width="120px" :model="formLabelAlign">
        <el-form-item
          label="标题："
          prop="name"
          :rules="[{ required: true, message: '请输入标题' }]"
        >
          <el-input
            placeholder="请输入标题"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.name"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="警示图片："
          prop="name"
          :rules="[{ required: true, message: '请上传警示图片' }]"
        >
          <el-upload
            class="avatar-uploader"
            :action="this.$store.state.uploadImage"
            :headers="this.$tools.config()"
            :data="{
              path_name: 'danger_icon',
            }"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="formLabelAlign.icon"
              :src="formLabelAlign.icon"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <!-- <el-form-item
          label="是否启用："
          prop="is_enable"
          :rules="[{ required: true, message: '请选择是否启用' }]"
        >
          <el-radio
            v-for="item in enable_list"
            v-model="formLabelAlign.is_enable"
            :key="item.id"
            :label="item.id"
            >{{ item.name }}</el-radio
          >
        </el-form-item> -->
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">{{
          type_name
        }}</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { iconsaveInfo, iconsave } from "../../assets/request/api";

export default {
  name: "student_redacy",

  props: {
    dialog_show: {
      type: Boolean,
    },
  },
  data() {
    return {
      // uploadImage:uploadImage,
      type_name: "保存",
      //要修改的id
      id: "",
      enable_list: [],
      formLabelAlign: {
        name: "",
        is_enable: "",
        icon: "",
      },
    };
  },

  methods: {
    way_show(id) {
      let froms = {};
      if (id) froms.id = id;
      iconsaveInfo(froms).then((res) => {
        let { info, enable_list } = res.data;
        this.enable_list = enable_list;
        if (id) {
          this.id = id;
          this.type_name = "修改";
          let { formLabelAlign } = this;
          let { name, is_enable, icon } = info;
          formLabelAlign.name = name;
          formLabelAlign.icon = icon;
          formLabelAlign.is_enable = is_enable;
        }
      });
    },
    handleAvatarSuccess(res, file) {
      console.log(res);
      this.formLabelAlign.icon = res.data.url;
    },
    //上传限制
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 20;
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/bmp";

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG ，png ，jpg ，bmp格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 20MB!");
      }
      return isJPG && isLt2M;
    },

    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { formLabelAlign, id } = this;
          if (id) formLabelAlign.id = id;
          iconsave(formLabelAlign).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              that.$emit("way_tableData");
              setTimeout(function () {
                that.$emit("update:refbool", false);
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //返回
    route_go() {
      this.$emit("update:refbool", false);
    },
  },
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 300px;
}
.pop-inp {
  width: 150px;
}
.tag-sty {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  margin: 0 5px 10px 0;
  width: 40px;
  height: 40px;
}
// .avatar-uploader {
//   width: 80px;
//   height: 80px;
// }

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
</style>