<template>
  <div>
    <div class="table_sty">
      <div class="froms">
        <p class="describe">筛选</p>
        <div class="segmentation"></div>
        <div class="from_submit">
          <el-form label-width="90px" :model="formLabelAlign" class="from">
            <el-form-item label="标识名称：">
              <el-input
                placeholder="请输入标识名称"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.name"
              ></el-input>
            </el-form-item>

            <!-- <el-form-item label="是否启用：">
              <el-select
                v-model="formLabelAlign.is_enable"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in enable_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
          </el-form>
          <div class="search_button">
            <div>
              <el-button
                @click="way_tableData(true)"
                type="primary"
                icon="el-icon-search"
                >查询</el-button
              >
              <el-button @click="on_reset" icon="el-icon-refresh-left"
                >重置</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="tab_sty">
        <div class="tab_top">
          <p>
            <span class="span1">一共{{ total }}个风险源标签</span>
            <span class="span2"
              >已选择 {{ more_List.length }} 个风险源标签</span
            >
            <i class="span_i el-icon-refresh" @click="way_tableData()"
              >数据更新</i
            >
          </p>
          <div>
            <!-- @click="on_addplus('/security/focus_groupsredact')" -->
            <el-button
              type="primary"
              @click="on_pop_addplus()"
              icon="el-icon-plus"
              >新建</el-button
            >
            <el-button  @click="on_deleteList" icon="el-icon-close"
              >删除</el-button
            >
            <!-- <el-button @click="on_export">导出</el-button> -->
          </div>
        </div>
        <div>
          <el-table
            highlight-current-row
            @current-change="way_singleselect"
            @selection-change="way_moreselect"
            :data="table_List"
            :header-cell-style="{ background: '#f8f8f9' }"
            style="flex: 1"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="id" label="警示图片ID"></el-table-column>
            <el-table-column prop="name" label="警示图片名称"></el-table-column>
            <el-table-column label="警示图片">
              <template slot-scope="scope">
                <el-image
                  style="width: 40px; height: 40px"
                  :src="scope.row.icon"
                >
                </el-image>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="address" label="是否启用">
              <template slot-scope="scope">
                <el-switch
                  @change="on_is_enable($event, scope.row.id)"
                  v-model="scope.row.is_enable"
                  :active-value="1"
                  :inactive-value="0"
                >
                </el-switch>
              </template>
            </el-table-column> -->

            <el-table-column prop="address" label="操作">
              <template slot-scope="scope">
                <el-link
                  class="list_sty"
                  type="primary"
                  @click="on_pop_addplus(scope.row.id)"
                  >修改</el-link
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="paging">
          <el-pagination
            @current-change="way_page"
            :page-size="page_size"
            layout="prev, pager, next, jumper"
            :total="total"
            :current-page="page"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 新建修改弹窗 -->
      <el-dialog
        :title="title"
      show-close
        :visible.sync="dialog_show"
        center
      >
        <riskimgredact
          v-if="dialog_show"
          ref="riskimgredact"
          :refbool.sync="dialog_show"
          @way_tableData="way_tableData"
        />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import table_minin from "../../assets/minin/table_minin";
import riskimgredact from "../../components/view_redact/risk_imgredact";
import {
  iconindex,
  icondelete,
  iconstatusupdate,
} from "../../assets/request/api";
export default {
  name: "risk_img",
  mixins: [table_minin],
  components: { riskimgredact },
  data() {
    return {
      is_extend: true,
      enable_list: [],
      formLabelAlign: {
        name: "",
        is_enable: "",
      },
      dialog_show: false,
      title: "",
    };
  },
  created() {
    this.fromData = this.formLabelAlign;
    this.url = iconindex;
    this.delete_Url = icondelete;
    this.is_enableUrl = iconstatusupdate;
    this.xl_attribute = "status_list";
    this.way_tableData();
  },
  methods: {
    way_extend(data) {
      this.enable_list = data.enable_list;
    },
    on_pop_addplus(id) {
      this.dialog_show = true;
      this.title = id ? "修改" : "新增";
      this.$nextTick(function () {
        this.$refs.riskimgredact.way_show(id);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #4f7afd;
  font-weight: bold;
  padding-bottom: 10px;
}
.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4f7afd;
}
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 200px;
}
.paging {
  text-align: center;
  padding: 10px;
}
.tab_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  button {
    margin-right: 10px;
  }
  p {
    display: flex;
    align-items: center;
  }
}
.span1 {
  font-size: 14px;
  color: #000000;
}
.span2 {
  font-size: 14px;
  margin-left: 10px;
  color: #797979;
}
.span_i {
  font-size: 14px;
  color: #797979;
  margin-left: 10px;
  cursor: pointer;
}
.el-divider__text {
  color: #0080ff;
  font-weight: bold;
}
.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}
.list_sty {
  margin-right: 10px;
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
</style>
